<template>
  <ion-page>
    <ion-header>
      <MainToolbar isSubpage :title="i18n.$t('tools.title')" />
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-list>
        <ion-item v-if="store.getters['auth/hasPermission']('Horse_Pose_Tools')" detail button @click="router.push('/tools/label-skeleton')">
          <ion-icon :icon="analytics" size="large" slot="start"></ion-icon>
          <ion-label>
            {{ i18n.$t('tools.label-skeleton.title') }}
          </ion-label>
        </ion-item>

        <ion-item v-if="store.getters['auth/hasPermission']('Horse_Pose_Tools')" detail button @click="router.push('/tools/analyse-skeleton')">
          <ion-icon :icon="barChart" size="large" slot="start"></ion-icon>
          <ion-label>
            {{ i18n.$t('tools.analyse-skeleton.title') }}
          </ion-label>
        </ion-item>

        <ion-item v-if="store.getters['auth/hasPermission']('Can_Design_Entry_Types')" detail button @click="router.push('/tools/design-report')">
          <ion-icon :icon="list" size="large" slot="start"></ion-icon>
          <ion-label>
            {{ i18n.$t('tools.design-report.title') }}
          </ion-label>
        </ion-item>

        <ion-item v-if="store.getters['auth/hasPermission']('Can_Edit_Entry_Types')" detail button @click="router.push('/tools/upload-reports')">
          <ion-icon :icon="cloudUpload" size="large" slot="start"></ion-icon>
          <ion-label>
            {{ i18n.$t('tools.upload-reports.title') }}
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonHeader, IonContent, IonList, IonItem, IonLabel, IonIcon } from '@ionic/vue';
import { useStore } from 'vuex';

import MainToolbar from '@/components/MainToolbar.vue';

import { analytics, list, barChart, cloudUpload } from 'ionicons/icons';

import { useI18n } from "@/utils/i18n";

import { useRouter } from 'vue-router';

export default  {
  name: 'Tools',
  components: { IonHeader, IonContent, IonPage, IonList, IonItem, IonLabel, IonIcon, MainToolbar },
  setup() {
    const i18n = useI18n();
    const router = useRouter();
    const store = useStore();

    return { i18n, router, store, analytics, list, barChart, cloudUpload };
  }
}
</script>

<style scoped>
</style>
